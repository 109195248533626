<template>
  <div id="ManageSupplier">
    <v-container>
      <v-card>
        <v-app-bar dense color="white">
          <v-toolbar-title>
            <h5>Daftar Operator</h5>
          </v-toolbar-title>
          <v-spacer />
          <v-icon @click="refreshData">mdi-refresh</v-icon>
        </v-app-bar>

        <v-card-text class="pa-0">
          <template>
            <v-data-table
              :headers="headers"
              :items="operatorData"
              :loading="loadingStatus"
              loading-text="Sedang Menyiapkan Data..."
              mobile-breakpoint="0"
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td>{{ props.item.operator_name }}</td>
                  <td>{{ props.item.operator_code }}</td>
                  <td>
                    <v-chip :color="getColor(props.item.flag_admin)" dark>{{
                      props.item.flag_admin === 1 ? "Admin" : "Karyawan"
                    }}</v-chip>
                  </td>
                  <td>
                    <v-btn
                      color="success"
                      class="ma-2 white--text"
                      @click="updateOperatorStatus(props.item)"
                      small
                    >
                      Ubah Status
                      <v-icon right small>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </template>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    headers: [
      { text: "Nama Operator", sortable: false },
      { text: "Kode Operator", sortable: false },
      { text: "Status", sortable: false },
      { text: "Aksi", sortable: false }
    ],
    methodColor: ["primary", "success"]
  }),

  mounted() {
    this.$store.dispatch("common/getAllOperator");
  },

  computed: {
    loadingStatus() {
      return this.$store.getters.getLoading;
    },
    operatorData() {
      return this.$store.getters["common/getOperator"];
    }
  },

  methods: {
    updateOperatorStatus(data) {
      const payload = {
        operator_code: data.operator_code,
        flag_admin: data.flag_admin === 0 ? 1 : 0,
        flag_active: 1
      };

      this.$store
        .dispatch("operator/updateOperatorStatus", payload)
        .then(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "success"
          });
        })
        .catch(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "error"
          });
        })
        .finally(() => this.$store.dispatch("common/getAllOperator"));
    },
    getColor(flag) {
      return this.methodColor[flag];
    },
    refreshData() {
      this.$store.dispatch("common/getAllOperator");
    }
  }
};
</script>
